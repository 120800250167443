import styles from "./Home.module.css";
import { useEffect, useState } from 'react';
import Stadistics from "../Stadistics/Stadistics";
import News from "../News/News";
import Feed from "../Feed/Feed";
import User from "../User/User.jsx";
import { useDispatch, useSelector } from "react-redux";
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import ModalUser from "../ModalUser/ModalUser.jsx";
import ModalMetrica from "../ModalMetrica/ModalMetrica.jsx";
import Otro from "../Otro/Otro.jsx";
import Listas from "../Listas/Listas.jsx";
import NavSecond from "../NavSecond/NavSecond.jsx";
import { getListas } from "../../actions/index.js";


const Home = ({ isOpen, user, inVisitor, notificacionesBool, desactivarNotificacion, userBool, logOut, dispacherPublicaciones, inAmigos, clickMessage, inEdit, allJugadores, listas, dispatcherAmigos, noticias, publicacionView, seguir }) => {
  const publicaciones = useSelector(state => state.allPublicaciones);
  const [metricaBool, setMetricaBool] = useState(false)
  const [metricaValor, setMetricaValor] = useState("")

  const [isVisitorBool, setIsVisitorBool] = useState(false)

  const [listasH, setListasH] = useState(listas)

  const dispatch = useDispatch()


  const [bool, setBool] = useState(true)
  const [threeBool, setTrheeBool] = useState(false)
  const [boolTwo, setBoolTwo] = useState(false)

  const [isAndroid, setIsAndroid] = useState(false)

  const userPublicaciones = useSelector(state => state.userPubliaciones)
  useEffect(() => {
    dispacherPublicaciones()
    dispatch(getListas(user.id))
  }, []);

  useEffect(() => {
    const handleDocumentClick = (event) => {
      if (notificacionesBool && event.target.closest('.modal-notificaciones') === null || userBool && event.target.closest('.modal-user') === null) {
        desactivarNotificacion();
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, [notificacionesBool, desactivarNotificacion, userBool]);

  const metricaClick = (metricaV) => {
    setMetricaBool(true)
    setMetricaValor(metricaV)
  }

  useEffect(() => {
    if (window.AndroidInterface) {
        setIsAndroid(true)
    }
}, [])

const clickBool = () => {
  setBool(true)
  setTrheeBool(false)
  setBoolTwo(false)
}

const clickBoolTwo = () => {
  setTrheeBool(false)
  setBool(false)
  setBoolTwo(true)
}

const clickBoolThree = () => {
  setBool(false)
  setTrheeBool(true)
  setBoolTwo(false)
}


  return (
    <div className={styles.conteiner}>

      {user.rol === "Jugador" ? 
        <div className={bool ? styles.partOne : styles.partDesactive}>
          <Stadistics detailUser={user} metricaClick={metricaClick}/>
        </div> 
      : 
        <div className={bool ? styles.partOne : styles.partDesactive}>
          <Otro inAmigos={inAmigos} user={user} isVisitorBool={isVisitorBool}/>
        </div>}

      {user.rol === "Jugador" ?
        <div className={!boolTwo ? styles.partTwo : styles.partDesactive}>
          <User user={user}/>
        </div> 
      : 
        <div className={threeBool ? styles.partThree : styles.partDesactive}>
          <Listas allJugadores={allJugadores} listas={listas} dispatcherAmigos={dispatcherAmigos} inVisitor={inVisitor} />
        </div>}

      <div className={boolTwo ? styles.partThree : styles.partDesactive}> <Feed user={user} publicaciones={publicaciones} inVisitor={inVisitor} userPublicaciones={userPublicaciones} noticias={noticias} publicacionView={publicacionView} seguir={seguir}/></div>

      {notificacionesBool === true && <ModalNotificaciones clickMessage={clickMessage} />}

      {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit} />}

      {metricaBool === true && <ModalMetrica setMetricaBool={setMetricaBool} metricaV={metricaValor} user={user}/>}

      <div className={styles.navSecond}><NavSecond  bool={bool} boolTwo={boolTwo} clickBool={clickBool} clickBoolTwo={clickBoolTwo} clickBoolThree={clickBoolThree} user={user} threeBool={threeBool} isVisitor={false}/></div>
    </div>
  );
}

export default Home;
