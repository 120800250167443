import React, { useEffect, useState } from 'react';
import styles from "./Publiaciones.module.css";
import userIcon from "../../assets/iconUser.png";
import likeIcon from "../../assets/premium.png"
import likeIconTrue from "../../assets/likeIconTrue.png"
import commentIcon from "../../assets/message.png"
import { useDispatch, useSelector } from 'react-redux';
import shareIcon from "../../assets/share.png"
import silver from "../../assets/silver.svg"
import bronce from "../../assets/bronze.svg"
import gold from "../../assets/gold.svg"
import axios from 'axios';
import { URL } from '../../link';
import { getAllPublicaciones, getUserPublicaciones, getVisitorPublicaciones, deletePublicacion, getNoticias } from '../../actions';
import VideoPlayer from './VideoPlayer';
import puntos from "../../assets/puntos.png"
import medalla from "../../assets/Medalla.png"
import medallaTrue from "../../assets/medallaTrue.png"
import 'animate.css';
import ModalPublicacion from '../ModalPublicacion/ModalPublicacion';
import { calcularEdad, loSigo } from '../funciones';
import { formFecha, conteLikeByValor, formatoLegible } from '../funciones';
import { useNavigate } from 'react-router';
import iconBack from "../../assets/back.svg"

const Publicaciones = (publicacion) => {

    const [comentBool, setComentBool] = useState(false)
    const [mensaje, setMensaje] = useState("");
    const [mensajeCompartir, setMensajeCompartir] = useState("")
    const user = JSON.parse(localStorage.getItem("dataUser"))
    const [compartirBool, setCompartirBool] = useState(false)
    const [reacionBool, setReacionBool] = useState(false)
    const maxLength = 250
    const [charCount, setCharCount] = useState(0)
    const maxLengthM = 250
    const [charCountM, setCharCountM] = useState(0)
    const [likeTrue, setLikeTrue] = useState(false)
    const [opcionesBool, setOpcionesBool] = useState(false)
    const [editBool, setEditBool] = useState(false)
    const [listaAplausos, setListaAplausos] = useState(false)
    const [listaMedallas, setListaMedallas] = useState(false)
    const [mensajeEdit, setMensajeEdit] = useState("")
    const seguidos = useSelector(state => state.seguidos)

    function existeRol(publicacion){
        if(publicacion.userRol === undefined || publicacion.userRol === null || publicacion.userRol === "" || publicacion.userRol === "admin"){
            return false
        }
        return true
    }

    function isBigSportData(publicacion){
        if(publicacion.origenName === "BigSportData"){
            return false
        }
        return true
    }

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const onClickComentar = async () => {
        if (comentBool === false) {
            setCompartirBool(false)
            setReacionBool(false)
            setComentBool(true)
        } else {
            setComentBool(false)
        }
    }

    const handleEditPubli = async () => {
        const data = {
            id: publicacion.id,
            newMensaje: mensajeEdit
        }

        try {
            const response = await axios.put(`${URL}/publicacion/edit`, data)
            console.log(response);
            dispatch(getAllPublicaciones(user.id))
            dispatch(getUserPublicaciones(user.id))
            dispatch(getVisitorPublicaciones(publicacion.idUpdate))
            dispatch(getNoticias())
            setEditBool(false)
            setMensajeEdit("")
        } catch (error) {
            console.log(error);
        }
    }

    const buscarTuLike = () => {
        return publicacion.likes.some(usuario => usuario.usuarioIdLike === user.id);
    };

    const buscarAplauso = () => {
        if (publicacion.aplausos.some(aplauso => aplauso.usuarioIdAplauso === user.id)) {
            setLikeTrue(true)
        } else {
            setLikeTrue(false)
        }
    }



    useEffect(() => {
        buscarAplauso()
    }, [])

    const addAplauso = async () => {
        try {
            const data = {
                publicacionID: publicacion.id,
                usuarioID: user.id,
                usuarioNombre: user.nombre,
                usuarioFoto: user.foto
            }
            const response = await axios.post(`${URL}/publicacion/aplausos`, data)
            dispatch(getAllPublicaciones(user.id))
            dispatch(getUserPublicaciones(user.id))
            dispatch(getVisitorPublicaciones(publicacion.idUpdate))
            dispatch(getNoticias())
            setLikeTrue(!likeTrue)
        } catch (error) {
            console.log(error);
        }
    }


    const postComentario = async () => {
        const data = {
            contenido: mensaje,
            emisorId: user.id,
        }
        try {
            const response = await axios.post(`${URL}/publicacion/comentar/${publicacion.id}`, data)
            dispatch(getAllPublicaciones(user.id))
            dispatch(getUserPublicaciones(user.id))
            dispatch(getVisitorPublicaciones(publicacion.idUpdate))
            dispatch(getNoticias())
            setComentBool(false)
            setMensaje("")
        } catch (error) {
            console.log(error);
        }
    }

    const addReaccion = async (valor) => {
        try {
            const data = {
                publicacionID: publicacion.id,
                usuarioID: user.id,
                valor: valor,
                usuarioNombre: user.nombre,
                usuarioFoto: user.foto
            }
            const response = await axios.post(`${URL}/publicacion/like`, data)
            dispatch(getAllPublicaciones(user.id))
            dispatch(getUserPublicaciones(user.id))
            dispatch(getVisitorPublicaciones(publicacion.idUpdate))
            setReacionBool(false)
            dispatch(getNoticias())
            setMensaje("")
        } catch (error) {
            console.log(error);
        }
    }

    const compartirTrue = () => {
        setCompartirBool(!compartirBool)
        if (comentBool === true) setComentBool(false)
        if (reacionBool === true) setReacionBool(false)
    }
    const mostrarReaccion = () => {
        setReacionBool(!reacionBool)
        if (comentBool === true) setComentBool(false)
        if (compartirBool === true) setCompartirBool(false)
    }

    const handleDelete = (id) => {
        dispatch(deletePublicacion(id))
    }

    const compartirPublicacion = async () => {
        try {
            const data = {
                userID: user.id,
                mensaje: mensajeCompartir,
                img: publicacion.img,
                video: publicacion.video,
                origenID: publicacion.id,
                origenUserID: publicacion.userId,
                origenName: publicacion.nombre,
                origenMensaje: publicacion.mensaje,
                origenApellido: publicacion.apellido,
                origenSegundoApellido: publicacion.segundoApellido,
                origenUserFoto: publicacion.foto,
                oldcreatedAt: publicacion.fecha
            }
            const response = await axios.post(`${URL}/publicacion/compartir`, data)
            dispatch(getAllPublicaciones(user.id))
            dispatch(getUserPublicaciones(user.id))
            dispatch(getVisitorPublicaciones(publicacion.idUpdate))
            dispatch(getNoticias())
            setCompartirBool(false)
            setMensajeCompartir("")
        } catch (error) {
            console.log(error);
        }
    }

    const mensajes = () => {
        navigate(`/message/`)
    }


    return (
        <div className={styles.container} id={publicacion.id}>
            <div className={styles.user}>
                <div className={styles.contUser}>
                    {publicacion.tipo === "publicacion" && <img className={styles.foto} src={publicacion.foto || userIcon} alt="" onClick={existeRol(publicacion) ? () => publicacion.inVisitor(publicacion.userId) : undefined} />}
                    {publicacion.tipo === "noticia" && <img className={styles.foto} src={publicacion.logo || userIcon} alt="" />}
                    {publicacion.tipo === "publicacion" && <div className={styles.contInfo}   onClick={existeRol(publicacion) ? () => publicacion.publicacionView(publicacion.id) : undefined}>
                        <h3 className={styles.nombre}>{publicacion.nombre}</h3>
                        <h4 className={styles.posicion}>{formatoLegible(publicacion.posicion)} - {calcularEdad(publicacion.dia, publicacion.mes, publicacion.anio) + " años"}</h4>
                    </div>}
                    {publicacion.tipo === "noticia" && <div className={styles.contInfo} >
                        <h3 className={styles.nombre}>{publicacion.empresa}</h3>
                        <h4 className={styles.posicion}>{publicacion.titulo}</h4>
                    </div>}
                </div>
                {publicacion.tipo !== "noticia" && <div className={styles.contTime}>
                    <span className={styles.time}>{formFecha(publicacion.fecha)}</span>
                    <img className={styles.puntos} src={puntos} alt="" onClick={() => setOpcionesBool(!opcionesBool)} />
                    {opcionesBool && <ModalPublicacion setOpcionesBool={setOpcionesBool} opcionesBool={opcionesBool} userId={publicacion.userId} publicacionId={publicacion.id} handleDelete={handleDelete} setEditBool={setEditBool} />}
                </div>}
            </div>
            <div className={styles.contMsg}  onClick={existeRol(publicacion) ? () => publicacion.publicacionView(publicacion.id) : undefined}>
                {!editBool ? <p className={styles.msg}>{publicacion.mensaje.charAt(0).toUpperCase() + publicacion.mensaje.slice(1)}</p> : <textarea placeholder={publicacion.mensaje} className={styles.mensajeEdit} value={mensajeEdit} onChange={(e) => setMensajeEdit(e.target.value)}></textarea>}
                {editBool && <div className={styles.contBtnTxt}>
                    <span className={styles.actualizar} onClick={handleEditPubli}>Actualizar</span>
                    <span className={styles.actualizar} onClick={() => setEditBool(false)}>Cancelar</span>
                </div>}
            </div>
            <div className={styles.contImg}>
                {publicacion.origenName && <div className={styles.contCompartido}>
                    <div className={styles.userOrigen}>
                        <img className={styles.foto} src={publicacion.origenUserFoto || userIcon} alt="" onClick={isBigSportData(publicacion) ? () => publicacion.inVisitor(publicacion.origenUserID) : undefined} />
                        <div>
                            <h3 className={styles.nombre}>{publicacion.origenName}</h3>
                            <span className={styles.time}> Publicó esto</span>
                        </div>
                    </div>
                    <div className={styles.contMsgOrigen}>
                        <p className={styles.msg}>{publicacion.origenMensaje.charAt(0).toUpperCase() + publicacion.origenMensaje.slice(1)}</p>
                    </div>
                </div>}
                <img className={styles.img} src={publicacion.img} alt="" />
                {publicacion.video !== "" &&
                    <div className={styles.contVideo}>
                        <VideoPlayer
                            width={720}
                            height={420}
                            video={publicacion.video}
                            controls
                        />
                    </div>}
            </div>
            {reacionBool && <div className={styles.medallas}>
                <div className={styles.contMedalla} onClick={() => { addReaccion("1") }}>
                    <img className={styles.medalla} src={bronce} alt="" />
                    <p className={styles.reactTextB}>{conteLikeByValor("1", publicacion)}</p>
                </div>
                <div className={styles.contMedalla} onClick={() => { addReaccion("2") }}>
                    <img className={styles.medalla} src={silver} alt="" />
                    <p className={styles.reactTextB}>{conteLikeByValor("2", publicacion)}</p>
                </div>
                <div className={styles.contMedalla} onClick={() => { addReaccion("3") }}>
                    <img className={styles.medalla} src={gold} alt="" />
                    <p className={styles.reactTextB}>{conteLikeByValor("3", publicacion)}</p>
                </div>
            </div>}
            {listaAplausos && <div className={styles.contAplausos}>
                <button className={styles.cerrar} onClick={() => setListaAplausos(false)}>X</button>
                <img className={styles.iconBack} src={iconBack} alt="" onClick={() => setListaAplausos(false)} />
                <div className={styles.secontContList}>
                    <div className={styles.contRow}>
                        <img className={styles.txtA} src={likeIconTrue} alt="" /> <span className={styles.reactTextC}>{publicacion.aplausos.length}</span>
                    </div>
                    <div className={styles.contAplausosList}>
                        {publicacion.aplausos.map((aplauso, index) => {
                            return (
                                <div id='listaAplausoBool' className={styles.contAplauso} key={index} >
                                    <div className={styles.userDate} onClick={() => publicacion.inVisitor(aplauso.usuarioID)}>
                                        <img className={styles.fotoAplauso} src={aplauso.Usuario.foto} alt="" />
                                        <p className={styles.aplausoTxt}>{aplauso.usuarioNombre}</p>
                                        {/* <p className={styles.userPosicion}>Interior Izquierdo - 17 años</p> */}
                                    </div>
                                    {user.id !== aplauso.usuarioID && (
                                        loSigo(seguidos, aplauso.usuarioID)
                                            ? <button className={styles.btnAdd} onClick={() => mensajes()}>Mensaje</button>
                                            : <button className={styles.btnAdd} onClick={() => publicacion.seguir(aplauso.usuarioID)}>Agregar</button>
                                    )}

                                </div>
                            )
                        })} </div>
                </div>
            </div>}
            {listaMedallas && <div className={styles.contAplausos}>
                <button className={styles.cerrar} onClick={() => setListaMedallas(false)}>X</button>
                <img className={styles.iconBack} src={iconBack} alt="" onClick={() => setListaMedallas(false)} />
                <div className={styles.secontContList}>
                    <div className={styles.contRow}>
                        <img className={styles.iconM} src={gold} alt="" /> <span className={styles.reactTextM}>{conteLikeByValor("1", publicacion)}</span>
                        <img className={styles.iconM} src={silver} alt="" /> <span className={styles.reactTextM}>{conteLikeByValor("2", publicacion)}</span>
                        <img className={styles.iconM} src={bronce} alt="" /> <span className={styles.reactTextM}>{conteLikeByValor("3", publicacion)}</span>
                    </div>
                    <div className={styles.contAplausosList}>
                        {publicacion.likes.map((medalla, index) => {
                            return (
                                <div id='listaAplausoBool' className={styles.contAplauso} key={index} >
                                    <div className={styles.userDate} onClick={() => publicacion.inVisitor(medalla.usuarioID)}>
                                        <img className={styles.fotoAplauso} src={medalla.Usuario.foto} alt="" />
                                        <img
                                            src={medalla.valor === "1" ? gold : medalla.valor === "2" ? silver : bronce}
                                            alt=""
                                            className={styles.iconMm}
                                        />

                                        <p className={styles.aplausoTxt}>{medalla.usuarioNombre}</p>
                                        {/* <p className={styles.userPosicion}>Interior Izquierdo - 17 años</p> */}
                                    </div>
                                    {user.id !== medalla.usuarioID && (
                                        loSigo(seguidos, medalla.usuarioID)
                                            ? <button className={styles.btnAdd} onClick={() => mensajes()}>Mensaje</button>
                                            : <button className={styles.btnAdd} onClick={() => publicacion.seguir(medalla.usuarioID)}>Agregar</button>
                                    )}

                                </div>
                            )
                        })} </div>
                </div>
            </div>}
            <div className={styles.contReaction}>
                <div className={styles.contR}>
                    <div className={styles.recomendarCont}>
                        <div className={likeTrue ? ["animate__animated", "animate__bounce", styles.recomendar].join(" ") : styles.recomendar}>
                            <img className={styles.icon} src={likeTrue ? likeIconTrue : likeIcon} alt="" onClick={addAplauso} />
                            <p className={likeTrue ? styles.reactTextTrue : styles.reactText} onClick={() => setListaAplausos(!listaAplausos)}>{publicacion.aplausos.length === 0 ? "" : publicacion.aplausos.length}</p>
                        </div>
                    </div>
                    <div className={styles.recomendarCont}>
                        <div className={styles.recomendar} onClick={onClickComentar}>
                            <img className={styles.icon} src={commentIcon} alt="" />
                            <p className={styles.reactText}>{publicacion.comentarios.length === 0 ? "" : publicacion.comentarios.length}</p>
                        </div>
                    </div>
                    <div className={styles.recomendarCont}>
                        <div className={styles.recomendar} onClick={compartirTrue}>
                            <img className={styles.icon} src={shareIcon} alt="" />
                            <p className={styles.reactText}>{publicacion.compartidos === 0 ? "" : publicacion.compartidos}</p>
                        </div>
                    </div>
                </div>
                <div className={styles.contM}>
                    <div className={styles.recomendarCont}>
                        <div className={styles.recomendar}>
                            <img className={styles.iconT} src={medallaTrue} alt="" onClick={mostrarReaccion}/>
                            <p className={styles.reactTextC} onClick={() => setListaMedallas(true)}>{publicacion.likes.length === 0 ? "" : publicacion.likes.length}</p>
                        </div>
                    </div>
                </div>
            </div>
            {compartirBool && <div className={styles.Contcompartir}>
                <div className={styles.msgComent}>
                    <img className={styles.foto} src={user.foto || userIcon} alt="" />
                    <div className={styles.contComent}>
                        <textarea
                            className={styles.textarea}
                            placeholder="Qué opinas de esto..."
                            value={mensajeCompartir}
                            onChange={(e) => {
                                const inputText = e.target.value;
                                if (inputText.length <= maxLength) {
                                    setMensajeCompartir(inputText);
                                    setCharCount(inputText.length);
                                }
                            }}
                        />
                        <div className={styles.charCount}>{charCount}/{maxLength}</div>
                        <button className={styles.btn} onClick={compartirPublicacion}>Compartir</button>
                    </div>

                </div>
            </div>}
            {comentBool &&
                <div className={styles.comentarios}>
                    <div className={styles.msgComent}>
                        <img className={styles.fotoPubli} src={user.foto || userIcon} alt="" />
                        <div className={styles.contComent}>
                            <textarea
                                className={styles.textarea}
                                placeholder="Tu comentario..."
                                value={mensaje}
                                onChange={(e) => {
                                    const inputText = e.target.value;
                                    if (inputText.length <= maxLength) {
                                        setMensaje(inputText);
                                        setCharCountM(inputText.length);
                                    }
                                }}
                            />
                            <div className={styles.charCount}>{charCountM}/{maxLengthM}</div>
                            <button className={styles.btn} onClick={postComentario}>Comentar</button>
                        </div>
                    </div>
                    <div className={styles.contComentarios}>
                        {publicacion.comentarios.map(comentario => {
                            return (
                                <div className={styles.comentario} key={comentario.id}>
                                    <div className={styles.commentUser}>
                                        <img className={styles.foto} src={comentario.Usuario?.foto || userIcon} alt="" />
                                        <div className={styles.comentCont}>
                                            <div className={styles.userComnt}>
                                                <h3 className={styles.userName}>{comentario.Usuario?.nombre}</h3>
                                                <p className={styles.fecha}>{formFecha(comentario.createdAt)}</p>
                                            </div>
                                            <p className={styles.commentText}>{comentario.contenido}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>}
        </div>
    );
}

export default Publicaciones;
