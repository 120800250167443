import React, { useEffect, useState, useRef } from 'react';
import styles from './Mensajes.module.css';
import iconUser from "../../assets/iconUser.png";
import axios from 'axios';
import { URL } from '../../link';
import { formatDistanceToNow } from 'date-fns';
import { es } from 'date-fns/locale';
import ModalNotificaciones from "../ModalNotificaciones/ModalNotificaciones.jsx";
import lupa from "../../assets/lupa.png"
import { calcularEdad, formatoLegible } from '../funciones.js';
import puntos from "../../assets/puntos.png"
import multimedia from "../../assets/multimedia.png"
import enviarI from "../../assets/enviar.png"
import {getUltimoMensaje, formFecha} from "../funciones.js"
import ModalUser from '../ModalUser/ModalUser.jsx';


const Mensajes = ({user, seguidos, mensajes, enviarMensajes, clickMessage, dispatcherAmigos, notificacionesBool, userBool, logOut, inEdit, inVisitor }) => {
    const [destinatario, setDestinatario] = useState({});
    const [mensaje, setMensaje] = useState('');
    const [destinatarioSeleccionado, setDestinatarioSeleccionado] = useState(null);

    const messagesRef = useRef(null);

    useEffect(() => {
        dispatcherAmigos()
    }, [])

    const enviar = async (e) => {
        if (mensaje.trim() !== "") {
            e.preventDefault();
            const data = {
                Remitente: user.id,
                Destinatario: destinatario.id,
                contenido: mensaje,
            };
            enviarMensajes(data);
            setMensaje("");
        }
        // await confirmarMensajeLeido(destinatario.id);
        setMensaje("");
    };

    const formatoTiempo = (fecha) => {
        const data = new Date(fecha)
        return formatDistanceToNow(data, { addSuffix: true, locale: es })
    }

    const selectDestinatario = async (ami) => {
        setDestinatario(ami);
        await confirmarMensajeLeido(ami.id);
        setDestinatarioSeleccionado(ami.id);
    };

    const filtrarMensajes = (you) => {
        return mensajes.filter(
            (msg) =>
                msg.RemitenteId === you || msg.DestinatarioId === you
        );
    };

    const cantidadMensajesNoLeidos = (remitenteId) => {
        return mensajes.filter((msg) => msg.RemitenteId === remitenteId && !msg.leido).length;
    };

    const confirmarMensajeLeido = async (remitenteId) => {
        const mensajeNoLeido = mensajes.filter((msg) => msg.RemitenteId === remitenteId && !msg.leido);
        for (let i = 0; i < mensajeNoLeido.length; i++) {
            try {
                await axios.put(`${URL}/mensaje/leido/${mensajeNoLeido[i].id}`);
            } catch (error) {
                console.log(error);
            }
        }
    };

    const handleKeyDown = (e) => {
        if (e.key === "Enter") {
            enviar(e);
        }
    };

    useEffect(() => {
        if (messagesRef.current) {
            messagesRef.current.scrollTop = messagesRef.current.scrollHeight;
        }
    }, [mensajes, destinatarioSeleccionado]);

    return (
        <div className={styles.container}>
            <div className={ Object.keys(destinatario).length === 0 ? styles.amigos : styles.amigosOculto}>
                <div className={styles.conTitle}>
                    <h3 className={styles.title}>Mensajes</h3>
                    {/* <img className={styles.icon} src={btnM} alt="" /> */}
                </div>
                <div>
                    <div className={styles.contInp}>
                        <input className={styles.buscador} type="text" placeholder='Buscar conversación' />
                        <div className={styles.iconoContainer}>
                            <img src={lupa} alt="" className={styles.icono} />
                        </div>
                    </div>
                    <ul className={styles.lista}>
                        {seguidos.length > 0 && seguidos.map((ami) => (
                            ami.id !== user.id && (
                                <li className={`${styles.contAmi} ${destinatarioSeleccionado === ami.id ? styles.destinatarioSeleccionado : ''}`} key={ami.id} onClick={() => selectDestinatario(ami)}>
                                    <div className={styles.user}>
                                        <img className={styles.foto} src={ami.foto || iconUser} alt="" />
                                        <div className={styles.infoMsg}>
                                            <div className={styles.estado}>
                                                <h4 className={styles.nombre}>{ami.nombre}</h4>
                                                <p className={styles.time}>{getUltimoMensaje(mensajes, ami.id)?.createdAt && formFecha(getUltimoMensaje(mensajes, ami.id)?.createdAt)}</p>
                                            </div>
                                            <h4 className={styles.msjPreview}>{getUltimoMensaje(mensajes, ami.id)?.contenido}</h4>
                                        </div>
                                    </div>
                                </li>
                            )
                        ))}
                    </ul>
                </div>
            </div>
            <div className={styles.bandeja}>
                {Object.keys(destinatario).length === 0 ? null : (
                    <div className={styles.containerMessage}>
                        <p className={styles.back} onClick={() => setDestinatario({})}>{`< Regresar`}</p>
                        <div className={styles.userHistory}>
                            <img className={styles.foto} src={destinatario.foto} alt="" />
                            <div className={styles.contName}>
                                <h4 className={styles.name}>{destinatario.nombre}</h4>
                                <p className={styles.posicion}>{formatoLegible(destinatario.posicion)} - {calcularEdad(destinatario.dia, destinatario.mes, destinatario.anio)} años</p>
                                <p className={styles.posicion}>{destinatario.direccion}, {destinatario.origen}</p>
                            </div>
                            <button className={styles.btnV} onClick={() => inVisitor(destinatario.id)}>Ver perfil</button>
                            <img className={styles.icon} src={puntos} alt="" />
                            {/* <p className={styles.line}>{destinatario.line === true ? "En linea" : "Desconectado"}</p> */}
                        </div>
                        <ul className={styles.messages} ref={messagesRef}>
                            {filtrarMensajes(destinatario.id).map((msg) => (
                                <li className={ msg.DestinatarioId === user.id ? styles.listMsgA : styles.listMsg} key={msg.id}>
                                    <div className={styles.msgRow}>
                                            <img className={styles.fotoB} src={msg.Remitente.foto || iconUser} alt="" />
                                        </div>
                                    <div className={msg.DestinatarioId === user.id ? styles.contMsgA : styles.contMsg}>
                                        <div className={msg.DestinatarioId === user.id ? styles.conMenA : styles.conMen}>
                                            <p className={msg.DestinatarioId === user.id ? styles.txtA : styles.txt}>{msg.contenido}</p>
                                        </div>
                                        <span className={styles.time}>{formatoTiempo(msg.updatedAt)}</span>
                                    </div>
                                </li>
                            ))}
                        </ul>
                        {Object.keys(destinatario).length > 0 && (
                            <div className={styles.envio} onSubmit={enviar}>
                                <div className={styles.contTextArea}>
                                    <img className={styles.fotoB} src={user.foto} alt="" />
                                    <textarea
                                        name="mensaje" id=""
                                        placeholder='Tu mensaje'
                                        value={mensaje}
                                        className={styles.cuadroTxt}
                                        onChange={(e) => setMensaje(e.target.value)}
                                        onKeyDown={handleKeyDown}></textarea>
                                </div>
                                <div className={styles.contBtn}>
                                    <button className={styles.btnC}>
                                        <img className={styles.iconB} src={multimedia} alt="" />
                                        Agregar multimedia</button>
                                    <button className={styles.btnE} onClick={enviar}>
                                    <img className={styles.iconC} src={enviarI} alt="" />
                                        Enviar mensaje</button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
            {notificacionesBool === true && <ModalNotificaciones clickMessage={clickMessage}/>}
            {userBool === true && <ModalUser logOut={logOut} inEdit={inEdit}/>}
        </div>
    );
};

export default Mensajes;
